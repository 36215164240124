.topbar{
    background: black;
    color: white;
    padding: 8px 0;
}

.social-icons i:hover{
    color: rgb(220, 167, 58);
    cursor: pointer;
}

.resto{
    border-right: 2px dashed lightgray;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}
.resto img{
    height: 160px;
    width: 260px;
    border-radius: 12px;
}
.resto-text{
    padding: 8px;
}

.resto-text p{
 font-size: .9rem;
}


@media (min-width: 1400px)
{
    .container {
        max-width: 1400px;
    }
}

@media (max-width: 500px) {
    .slider-area .h1-slider-caption h3::after{
        width: 0!important;
    }

    .resto{
        flex-direction: column;
        border-right: 0;
        border-bottom: 2px dashed lightgray;
        margin-top: 16px;
    }

    .resto img{
        width: 100%;
    }
}

.about-buttons{
    display: flex;
    flex-direction: column;
}

.about-buttons button{
    margin-top: 16px;
    background: white;
    color: black;
    outline: 1px solid #dca73a;
    width: 100%;
}
.about-buttons button img{
    margin-right: 4px;
    height: 18px;
}

.about-btn-active{
    background: #dca73a!important;
    color: white!important;
}

.about-plage{
    display: flex;
}

.about-plage img{
    border-radius: 12px;
    margin-right: 32px;
    margin-left: 4px;
    /* height: 500px; */
}


.about-plage .plage-details li{
margin-top: 16px;
    list-style: unset;
}

.facility{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.facility img{
    margin-right: 0;
    margin-left: 0;
    height: 25px;
}

.facility p{
    text-align: center;
}

.about-gallery img{
    width: 100%;
    border-radius: 12px;
    margin-top: 16px;
}


.h-actions button{
    width: 60px;
    padding: 8px;
    outline: 0;
    border: none;
    margin: 1px;
    cursor: pointer;
}

.p-20{
    padding: 20px!important;
}

.modal-lg{
    max-width: 1600px;
}

table input{
    width: 60px;
}
.modal-dialog-mw{
    max-width: unset;
}

.image-salle-conference{
    position: absolute;
    left: 0;
    content: "";
    top: 0;
    bottom: 0;
    width: 50%;
    border-radius: 0 5px 0 0;
    background-size: cover;
    background-repeat: no-repeat;
}


#conference-room-mobile{
    position: relative;
    margin-top: 32px;
}


#conference-room-mobile .conference-details{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255, 0.8);
    border-radius: 4px;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes glowing {
    0% { text-shadow: 0 0 10px #ff0000; }
    50% { text-shadow: 0 0 20px #ff0000; }
    100% { text-shadow: 0 0 10px #ff0000; }
  }

  .glow-text {
    color: #ff0000;
    /* font-size: 24px; */
    font-weight: bold;
    animation: glowing 2s infinite;
  }


  /* Add your styles here */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

  }
  
  .ss-modal {
    background: #fff;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  
  .ss-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #007bff;
    color: #fff;
  }
  
  .ss-modal-title {
    margin: 0;
  }
  
  .ss-modal-body {
    max-height: 100vh;
    padding: 0;
  }
  
  .ss-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    background: #f8f9fa;
  }

  .ss-buttons{
    position: absolute;
    z-index: 9999;
    top: 8px;
    right: 8px;
    width: 100%;
    text-align: center;
  }

  .ss-buttons button{
    border-radius: 0;
    padding: 16px;
    background: white;
    color: red;
    font-weight: bold;
  }

  .ss-reserver{
   
  }
  .ss-fermer{

  }
  